import React from "react";
import Back from "../SignUp/Back";
import styles from "./Privacy.module.css";
const TermsOfUse = () => {
  return (
    <div>
      <Back />
      <div className={styles.privacy}>
        <h1 className={styles.heading}>Terms of Use Policy</h1>
        <br />
        <br />
        Please read these Terms of Use carefully before using our platform and
        services. By accessing or using our platform, you agree to be bound by
        these Terms of Use. If you do not agree with any part of these terms,
        please refrain from using our platform.
        <br />
        <br />
        <ol type="1">
          <li>
            <strong>Introduction</strong>
            <br />
            <br />
            MedInclude operates an online platform that helps patients better
            understand medical information by making it available in simple
            terms and multiple languages.
            <br />
            <br />
            By using our platform, you acknowledge that MedInclude is not a
            healthcare provider and does not provide medical advice. Our
            platform is intended to help transcribe medical information from
            your healthcare provider and should not substitute professional
            medical advice, diagnosis, or treatment. Always consult a qualified
            healthcare professional for any medical concerns or questions.
          </li>
          <br />
          <br />
          <li>
            <strong>User Eligibility and Account</strong>
            <br />
            <br />
            To use our platform, you must be at least 18 years old and have the
            legal capacity to enter into a binding agreement. By creating an
            account, you represent and warrant that you meet these requirements.
            <br />
            <br />
            You are responsible for maintaining the confidentiality of your
            account credentials, including your username and password. Any
            activity that occurs under your account is your responsibility. If
            you become aware of any unauthorized use of your account, please
            notify us immediately.
          </li>
          <br />
          <br />
          <li>
            <strong>User Obligations</strong>
            <br />
            <br />
            You agree to use our platform for lawful purposes and in accordance
            with these Terms of Use. You shall not:
            <br />
            <br /> a) Violate any applicable laws, regulations, or third-party
            rights.
            <br /> b) Engage in any fraudulent, abusive, or deceptive behavior.
            <br /> c) Use our platform to transmit any viruses, malware, or
            harmful content.
            <br /> d) Interfere with the proper functioning of our platform or
            circumvent any security measures.
            <br /> e) Collect or harvest any user data without our consent
            <br />
            <br />
            You are solely responsible for the accuracy and completeness of any
            information you provide on our platform. You agree to update your
            information promptly if there are any changes.
          </li>
          <br />
          <br />
          <li>
            <strong>Intellectual Property Rights</strong>
            <br />
            <br />
            Our platform and its contents, including but not limited to text,
            graphics, logos, images, videos, and software, are owned or licensed
            by MedInclude and are protected by intellectual property laws. You
            may not use, reproduce, modify, or distribute any part of our
            platform without our prior written consent.
            <br />
            <br />
            By using our platform, you grant MedInclude a worldwide,
            non-exclusive, royalty-free license to use, reproduce, distribute,
            and display any content you submit through the platform for the
            purpose of providing our services. You represent and warrant that
            you have the necessary rights to grant this license.
          </li>
          <br />
          <br />
          <li>
            <strong>Third-Party Links and Content</strong>
            <br />
            <br />
            Our platform may contain links to third-party websites or resources.
            These links are provided for your convenience only, and we do not
            endorse or control the content of any linked websites. Your
            interactions with any third-party websites are at your own risk, and
            you should review their terms of use and privacy policies.
          </li>
          <br />
          <br />
          <li>
            <strong>Privacy</strong>
            <br />
            <br />
            Our collection and use of your personal information are governed by
            our Privacy Policy, which forms an integral part of these Terms of
            Use. By using our platform, you consent to the collection, use, and
            disclosure of your personal information as described in our Privacy
            Policy.
          </li>
          <br />
          <br />
          <li>
            <strong>Disclaimer of Warranties</strong>
            <br />
            <br />
            We strive to provide accurate and up-to-date information on our
            platform. However, we do not warrant the completeness, reliability,
            or accuracy of any information provided. Your use of our platform is
            at your own risk.
            <br />
            <br />
            MedInclude disclaims all warranties, whether express, implied, or
            statutory, including but not limited to warranties.
          </li>
          <br />
          <br />
        </ol>
      </div>
    </div>
  );
};

export default TermsOfUse;
