import React from "react";
import { useUserAuth } from "../store/UserAuthContext";

export const ComponentToPrint = React.forwardRef(({ documentData }, ref) => {
  // console.log(documentData)
  return (
    <div ref={ref} style={{}}>
      <h1 style={{ textAlign: "center", marginTop: "1rem" }}>MedInclude</h1>
      <br />
      <section style={{ textAlign: "justify", paddingLeft: "3rem", paddingRight: "3rem" }}>
        <span>
          <strong>Date: </strong>
        </span>
        {documentData.Dates}
        <br />
        <span>
          <strong>User Input Medical Report: </strong>
        </span>
        {documentData.userInputs}
        <br />
        <br />
        <span>
          <strong>Transcribed Medical Report: </strong>
        </span>
        {documentData.userTranscribedInput}
        <br />
        <br />
        <span>
          <strong>Translate Medical Report: </strong>
        </span>
        {documentData.userTranslateInput}
      </section>
    </div>
  );
});

// export const ComponentToPrint = React.forwardRef((props, ref) => {
//   const {userTranslateInput} = useUserAuth();
//   return (
//     <div ref={ref}>
//       <h1 style={{textAlign:"center",marginTop:"1rem"}}>MedInclude</h1>
//       <br/>
//       <section style = {{paddingLeft:"1.2rem", paddingRight:"1rem"}}>
//       {userTranslateInput}
//       </section>
//       </div>
//   );
// });
