const data = [
  { id: 0, language: "Choose language", code: "" },
  { id: 1, language: "Albanian", code: "sq" },
  { id: 2, language: "Amharic", code: "am" },
  { id: 3, language: "Arabic", code: "ar" },
  { id: 4, language: "Armenian", code: "hy" },
  { id: 5, language: "Bengali", code: "bn" },
  { id: 6, language: "Bosnian", code: "bs" },
  { id: 7, language: "Croatian", code: "hr" },
  { id: 8, language: "Czech", code: "cs" },
  { id: 9, language: "Dari", code: "fa-AF" },
  { id: 10, language: "Dutch", code: "nl" },
  { id: 11, language: "English", code: "en" },
  { id: 12, language: "Farsi (Persian)", code: "fa" },
  { id: 13, language: "Filipino, Tagalog", code: "tl" },
  { id: 14, language: "French", code: "fr" },
  { id: 15, language: "French (Canada)", code: "fr-CA" },
  { id: 16, language: "German", code: "de" },
  { id: 17, language: "Greek", code: "el" },
  { id: 18, language: "Gujarati", code: "gu" },
  { id: 19, language: "Hindi", code: "hi" },
  { id: 20, language: "Hungarian", code: "hu" },
  { id: 21, language: "Italian", code: "it" },
  { id: 22, language: "Korean", code: "ko" },
  { id: 23, language: "Malayalam", code: "ml" },
  { id: 24, language: "Pashto", code: "ps" },
  { id: 25, language: "Polish", code: "pl" },
  { id: 26, language: "Portuguese (Brazil)", code: "pt" },
  { id: 27, language: "Portuguese (Portugal)", code: "pt-PT" },
  { id: 28, language: "Punjabi", code: "pa" },
  { id: 29, language: "Romanian", code: "ro" },
  { id: 30, language: "Russian", code: "ru" },
  { id: 31, language: "Serbian", code: "sr" },
  { id: 32, language: "Slovak", code: "sk" },
  { id: 33, language: "Somali", code: "so" },
  { id: 34, language: "Spanish", code: "es" },
  { id: 35, language: "Spanish (Mexico)", code: "es-MX" },
  { id: 36, language: "Tamil", code: "ta" },
  { id: 37, language: "Telugu", code: "te" },
  { id: 38, language: "Turkish", code: "tr" },
  { id: 39, language: "Ukrainian", code: "uk" },
  { id: 40, language: "Urdu", code: "ur" },
  { id: 41, language: "Vietnamese", code: "vi" },
];

export default data;

// const data = [
//   { id: 0, language: "Choose language", code: "" },
//   { id: 1, language: "Afrikaans", code: "af" },
//   -{ id: 2, language: "Albanian", code: "sq" },
//   -{ id: 3, language: "Amharic", code: "am" },
//   -{ id: 4, language: "Arabic", code: "ar" },
//   -{ id: 5, language: "Armenian", code: "hy" },
//   { id: 6, language: "Azerbaijani", code: "az" },
//   -{ id: 7, language: "Bengali", code: "bn" },
//   -{ id: 8, language: "Bosnian", code: "bs" },
//   { id: 9, language: "Bulgarian", code: "bg" },
//   { id: 10, language: "Catalan", code: "ca" },
//   { id: 11, language: "Chinese (Simplified)", code: "zh" },
//   { id: 12, language: "Chinese (Traditional)", code: "zh-TW" },
//   -{ id: 13, language: "Croatian", code: "hr" },
//   -{ id: 14, language: "Czech", code: "cs" },
//   { id: 15, language: "Danish", code: "da" },
//   -{ id: 16, language: "Dari", code: "fa-AF" },
//   -{ id: 17, language: "Dutch", code: "nl" },
//   { id: 18, language: "English", code: "en" },
//   { id: 19, language: "Estonian", code: "et" },
//   -{ id: 20, language: "Farsi (Persian)", code: "fa" },
//   -{ id: 21, language: "Filipino, Tagalog", code: "tl" },
//   { id: 22, language: "Finnish", code: "fi" },
//   { id: 23, language: "French", code: "fr" },
//   { id: 24, language: "French (Canada)", code: "fr-CA" },
//   { id: 25, language: "Georgian", code: "ka" },
//   -{ id: 26, language: "German", code: "de" },
//   -{ id: 27, language: "Greek", code: "el" },
//   -{ id: 28, language: "Gujarati", code: "gu" },
//   { id: 29, language: "Haitian Creole", code: "ht" },
//   { id: 30, language: "Hausa", code: "ha" },
//   { id: 31, language: "Hebrew", code: "he" },
//   -{ id: 32, language: "Hindi", code: "hi" },
//   -{ id: 33, language: "Hungarian", code: "hu" },
//   { id: 34, language: "Icelandic", code: "is" },
//   { id: 35, language: "Indonesian", code: "id" },
//   { id: 36, language: "Irish", code: "ga" },
//   -{ id: 37, language: "Italian", code: "it" },
//   { id: 38, language: "Japanese", code: "ja" },
//   { id: 39, language: "Kannada", code: "kn" },
//   { id: 40, language: "Kazakh", code: "kk" },
//   -{ id: 41, language: "Korean", code: "ko" },
//   { id: 42, language: "Latvian", code: "lv" },
//   { id: 43, language: "Lithuanian", code: "lt" },
//   { id: 44, language: "Macedonian", code: "mk" },
//   { id: 45, language: "Malay", code: "ms" },
//   -{ id: 46, language: "Malayalam", code: "ml" },
//   { id: 47, language: "Maltese", code: "mt" },
//   { id: 48, language: "Marathi", code: "mr" },
//   { id: 49, language: "Mongolian", code: "mn" },
//   { id: 50, language: "Norwegian (Bokmål)", code: "no" },
//   -{ id: 51, language: "Pashto", code: "ps" },
//   -{ id: 52, language: "Polish", code: "pl" },
//   -{ id: 53, language: "Portuguese (Brazil)", code: "pt" },
//   { id: 54, language: "Portuguese (Portugal)", code: "pt-PT" },
//   -{ id: 55, language: "Punjabi", code: "pa" },
//   -{ id: 56, language: "Romanian", code: "ro" },
//   -{ id: 57, language: "Russian", code: "ru" },
//   -{ id: 58, language: "Serbian", code: "sr" },
//   { id: 59, language: "Sinhala", code: "si" },
//   -{ id: 60, language: "Slovak", code: "sk" },
//   { id: 61, language: "Slovenian", code: "sl" },
//   -{ id: 62, language: "Somali", code: "so" },
//   -{ id: 63, language: "Spanish", code: "es" },
//   { id: 64, language: "Spanish (Mexico)", code: "es-MX" },
//   { id: 65, language: "Swahili", code: "sw" },
//   { id: 66, language: "Swedish", code: "sv" },
//   -{ id: 67, language: "Tamil", code: "ta" },
//   -{ id: 68, language: "Telugu", code: "te" },
//   { id: 69, language: "Thai", code: "th" },
//   -{ id: 70, language: "Turkish", code: "tr" },
//   -{ id: 71, language: "Ukrainian", code: "uk" },
//   -{ id: 72, language: "Urdu", code: "ur" },
//   { id: 73, language: "Uzbek", code: "uz" },
//   -{ id: 74, language: "Vietnamese", code: "vi" },
//   { id: 75, language: "Welsh", code: "cy" },
// ];