import React from "react";
import Back from "../SignUp/Back";
import styles from "./Privacy.module.css";
const Privacy = () => {
  return (
    <div>
      <Back />
      <div className={styles.privacy}>
        <h1 className={styles.heading}>Privacy Policy </h1>
        <br />
        <br />

        <h2>Commitment to Privacy</h2>
        <br />
        <div>
          MedInclude is committed to protecting the privacy of our users and
          maintaining the confidentiality of the information you provide to us.
          This Privacy Policy outlines the types of information we collect from
          users of the platform, how we use and protect that information, and
          your rights regarding your personal data.
          <br />
          <br />
          By accessing or using our Platform, you acknowledge that you have
          read, understood, and agree to be bound by this Privacy Policy. If you
          do not agree with any part of this Privacy Policy, please do not use
          our Platform
        </div>
        <br />
        <br />
        {/* <h2>Applicability of This Privacy Policy</h2>
        <br />
        <p>
          Our Privacy Policy attests to our commitment to privacy and
          demonstrates the ways we ensure that patient privacy is protected. Our
          Privacy Policy applies to the personal health information of all our
          patients that is in our possession and control.
        </p>
        <br />
        <br />
        <h2>What is Personal Health Information?</h2>
        <br />
        <p>
          Personal health information means identifying information about an
          individual relating to their physical or mental health (including
          medical history), the providing of health care to the individual,
          payments or eligibility for health care, organ and tissue donation and
          health number.
        </p>
        <br />
        <br /> */}

        <ol type="1">
          <li>
            <strong>Collection of Information</strong>
            <br />
            <br />
            <ol type="i">
              <li>
                Personal Information: We may collect personal information that
                you voluntarily provide to us when using our Platform. This
                includes information such as your name, email address, contact
                details, date of birth, gender, and any other information you
                choose to provide. We may also collect personal information from
                third-party sources, such as healthcare providers, insurance
                companies, and other organizations.
              </li>
              <br />
              <li>
                Health Information: In order to provide you with the services
                offered through our Platform, we may collect and process certain
                health-related information, which may include medical history,
                health conditions, medications, and other relevant health data.
                We will only collect such information with your explicit consent
                and in accordance with applicable laws and regulations.
              </li>
              <br />
              <li>
                Usage Information: When you access or use our Platform, we may
                automatically collect certain information about your usage
                patterns, device information, IP address, browser type, and
                other technical information.
              </li>
            </ol>
          </li>
          <br />
          <br />
          <li>
            <strong>Use of Information</strong>
            <br />
            <br />
            <ol type="i">
              <li>
                Provide Services: We may use the information we collect to
                provide you with our services, personalize your experience, and
                improve the functionality of the Platform. We may also use it to
                respond to your inquiries and requests.
              </li>
              <br />
              <li>
                Communication: We may use your contact information to
                communicate with you, respond to your inquiries, provide
                customer support, and send you important updates or promotional
                materials related to our services. You can opt-out of receiving
                marketing communications at any time.
              </li>
              <br />
              <li>
                Aggregated Data: We may use aggregated and anonymized data for
                statistical and analytical purposes to improve our services,
                conduct research, and generate reports.
              </li>
              <br />
              <li>
                Legal Compliance: We may use your information to comply with
                applicable laws, regulations, or legal processes, or to enforce
                our rights or defend against legal claims.
              </li>
            </ol>
          </li>
          <br />
          <br />
          <li>
            <strong>Data Sharing and Disclosure</strong>
            <br />
            <br />
            <ol type="i">
              <li>
                Service Providers: We may share your personal information with
                trusted third-party service providers who assist us in operating
                our Platform, conducting business activities, or providing
                services on our behalf. These service providers are
                contractually obligated to protect your information and use it
                solely for the purposes specified by us.
              </li>
              <br />
              <li>
                Legal Requirements: We may disclose your information if required
                to do so by law, court order, or government request. We may also
                disclose your information to protect our rights, property, or
                safety, or the rights, property, or safety of others.
              </li>
              <br />
              <li>
                Business Transfers: In the event of a merger, acquisition, or
                any form of sale or transfer of some or all of our assets, we
                may disclose your information to the acquiring party as part of
                the due diligence process or the transferred assets.
              </li>
              <br />
              <li>
                Research: With your consent, we may share de-identified and
                aggregated data to researchers who are studying health-related
                topics. This data, which does not contain personally
                identifiable information, helps researchers gain valuable
                insights into various health conditions, treatments, and
                outcomes. All shared data is handled securely, and strict
                confidentiality measures are in place to safeguard your
                information.
              </li>
            </ol>
          </li>
          <br />
          <br />
          <li>
            <strong>Data Security</strong>
            <br />
            <br />
            We implement appropriate technical and organizational measures to
            protect your personal information from unauthorized access,
            disclosure, alteration, or destruction. However, no method of
            transmission over the internet or electronic storage is 100% secure,
            and we cannot guarantee the absolute security of your information.
          </li>
          <br />
          <br />
          <li>
            <strong>Retention of Information</strong>
            <br />
            <br />
            We retain personal information for as long as necessary to fulfill
            the purposes for which it was collected, or as required by law. When
            personal information is no longer required, we securely destroy or
            delete it.
          </li>
          <br />
          <br />
          <li>
            <strong>Changes to this Privacy Policy</strong>
            <br />
            <br />
            We may update this privacy policy from time to time by posting a new
            version on our website. We encourage you to check our website
            periodically for updates. We will also share an email alerting you
            to any update to our privacy policy.
          </li>
          <br />
          <br />
          <li>
            <strong>Your Rights</strong>
            <br />
            <br />
            You have the right to access, update, correct, or delete your
            personal information held by us. You may also have the right to
            restrict or object to the processing of your information, and to
            request the transfer of your data to another party. To exercise your
            rights or for any privacy-related inquiries, please contact us.
          </li>
          <br />
          <br />
          <li>
            <strong>Contact Us</strong>
            <br />
            <br />
            If you have any questions or concerns about this privacy policy or
            our handling of personal information, please contact us at
            gbola@medinclude.com.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Privacy;
